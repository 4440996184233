<template>
    <v-container grid-list-xl fluid>
        <h1>Expedição de pacotes</h1>
        <v-divider class="my-4" />

        <v-flex xs12 v-if="items && items.length">
            <v-text-field :label="$t('Código de barras')" v-model="ticket" clearable outlined hide-details v-on:keyup.enter="bip()" append-icon="qr_code_scanner"
                append-outer-icon="mdi-send" @click:append="openReadQRCode" @click:append-outer="bip()" />
            <v-divider class="my-4" />

            <v-alert outlined color="error" prominent border="top" icon="error" v-if="errorList">
                <p v-html="errorList"></p>
            </v-alert>

            <div class="list-group-item" v-for="(route, index) in routes" :key="index">
                <v-list-item class="list-group-item" dense>
                    <v-list-item-content>
                        <v-list-item-title>
                            {{ route.name }} - {{ route.bipped }} / {{ route.total }}
                        </v-list-item-title>
                        <v-list-item-subtitle v-if="route.needReceive && route.needReceive.length > 0">
                            Feito recebimento de: {{ route.needReceive.reduce((result, i) => { result[i] = (result[i] || 0) + 1; return result; }, {}) }}
                        </v-list-item-subtitle>
                        <v-list-item-subtitle v-if="route.driver && route.driver.length > 0">
                            Motorista(s): {{ route.driver.join(" / ") }}
                        </v-list-item-subtitle>
                        <v-list-item-subtitle>
                            <v-progress-linear height="10" :color="route.bipped == route.total ? 'success' : 'primary'"
                                :value="route.total > 0 ? (route.bipped / route.total) * 100 : 0" />
                        </v-list-item-subtitle>
                    </v-list-item-content>
                </v-list-item>
                <v-divider :key="index" />
            </div>

            <v-divider class="my-4" />

            <v-text-field :label="$t('Filtrar...')" v-model="filterName" clearable />

            <v-data-table :sort-by.sync="sortBy" :sort-desc.sync="sortDesc" :headers="headers" :items="listWithFilter" class="elevation-1 mb-12">
                <template v-slot:item.bippedAt="{ item }">
                    {{ item.bippedAt ? $moment(item.bippedAt).format("DD/MM/YYYY HH:mm") : null }}
                </template>
                <template v-slot:item.actions="{ item }">
                    <v-chip v-if="item.bipped == false" class="ma-0" outlined color="grey"> Aguardando bipagem </v-chip>
                    <v-btn v-else x-small color="success" @click="print(item)">Gerar Etiqueta</v-btn>
                </template>
            </v-data-table>
        </v-flex>
        <v-flex xs12 v-else>
            <v-file-input label="Carregue o arquivo de rotas" @change="handleFileUpload" accept=".xlsx, .xls, .csv" outlined prepend-icon="mdi-file-excel" />
            <v-switch v-model="printAutomatic" :label="$t('Gerar etiqueta automaticamente ao bipar')" class="my-2 py-0" />
            <v-alert outlined color="info" prominent border="top" icon="school">
                <strong>Lembrete</strong>: <br><br> - Arquivo precisa conter as colunas "Código" e "Motorista" <br> - "Motorista" precisa conter a rota e o indice
                separado por
                "__"
            </v-alert>
        </v-flex>

        <ConfirmModal confirmText="Confirmar" cancelText="Cancelar" ref="confirm" />
        <BarCode :show="showQRCode" @close="showQRCode = false" @onRead="readedQRCode" />
        <iframe ref="pdfIframe" style="display: none;" />
    </v-container>
</template>

<script>
import ConfirmModal from "@/components/core/ConfirmModal";
import ticketPDF from "@/pdf/ticketPDF";
import BarCode from "@/components/core/BarCode";
import * as XLSX from 'xlsx';

export default {
    components: { ConfirmModal, BarCode },

    data() {
        return {
            sortBy: 'bippedAt',
            sortDesc: true,
            pdfUrl: null,
            printAutomatic: true,
            ticketPDF,
            ticket: null,
            filterName: null,
            showQRCode: false,
            items: [],
            routes: [],
            errorList: null,
            headers: [
                { text: this.$t("Código de barra"), value: "code", align: "left", sortable: false },
                { text: this.$t("Cliente"), value: "client", align: "left", sortable: false },
                { text: this.$t("Rota"), value: "route", align: "left" },
                { text: this.$t("Ordem"), value: "order", align: "left" },
                { text: this.$t("Motorista"), value: "driver", align: "left" },
                { text: this.$t("Hora da bipagem"), value: "bippedAt", align: "center" },
                { text: this.$t("Integração"), value: "integrationControl", align: "center" },
                { text: this.$t("Ações"), value: "actions", align: "center" },
            ],
        };
    },

    computed: {
        listWithFilter() {
            let ret = this.items;
            if (this.filterName) {
                let exp = new RegExp(this.filterName.trim().normalize("NFD").replace(/[\u0300-\u036f]/g, ""), "i");
                ret = ret.filter((item) =>
                    typeof item === "object" &&
                    item !== null &&
                    exp.test(JSON.stringify(Object.values(item)).normalize("NFD").replace(/[\u0300-\u036f]/g, ""))
                );
            }
            return ret;
        },
    },

    methods: {
        async print(item) {
            let doc = await ticketPDF.create(item);
            const pdfBlob = doc.output("blob");
            const pdfUrl = URL.createObjectURL(pdfBlob);

            const iframe = this.$refs.pdfIframe;
            iframe.src = pdfUrl;
            iframe.onload = () => {
                iframe.contentWindow.print();
                URL.revokeObjectURL(pdfUrl);
            };
        },
        openReadQRCode() {
            this.showQRCode = true;
        },
        readedQRCode(QRCode) {
            this.ticket = QRCode;
        },
        markeBip(item, route, serverData = null) {
            if (item.bipped == false) {
                route.bipped++;
                item.bipped = true;
                item.bippedAt = this.$moment();
            }

            this.ticket = null;

            if (serverData) {
                if (serverData.client)
                    item.client = `${serverData.client.name} (${serverData.client.address})`;
                if (serverData.integrationControl) {

                    if (serverData.needReceive == true) {
                        route.needReceive.push(serverData.integrationControl);

                        item.integrationControl = `${serverData.integrationControl} (Feito recebimento)`;
                    } else {
                        item.integrationControl = serverData.integrationControl;
                    }
                }

            }

            this.$eventHub.$emit("msgSuccess", "Pacote registrado com sucesso.");

            if (this.printAutomatic) {
                this.print(item);
            }

        },
        async bipServer(item, route) {
            await this.$http
                .post(`packages/shipment`, { ticket: this.ticket })
                .then((result) => {
                    this.markeBip(item, route, result)
                })
                .catch(() => {
                    this.markeBip(item, route, null)
                    // Adson pediu mesmo que o pacote não exista deve seguir sem erro
                    // this.$eventHub.$emit("msgError", "Erro ao localizar pedido");
                });
        },
        bip() {
            let i = this.items.filter(i => i.code == this.ticket)
            if (i && i.length > 0) {
                if (i.length == 1) {
                    let r = this.routes.filter(e => e.name === i[0].route);
                    if (r.length > 0) {
                        if (i[0].bipped == false) {
                            this.bipServer(i[0], r[0]);
                        } else {
                            this.markeBip(i[0], r[0]);
                            // Adson pediu para deixar bipar mais vezes o mesmo pacote
                            // this.$eventHub.$emit("msgError", `Pacote já bipado!`);
                        }
                    }
                } else {
                    this.$eventHub.$emit("msgError", `Mais de um pacote com esse código '${this.ticket}'!`);
                    this.ticket = null;
                }
            } else {
                this.$eventHub.$emit("msgError", `Código '${this.ticket}' não localizado`);
                this.ticket = null;
            }
        },
        addError(i) {
            if (this.errorList == null) {
                this.errorList = "Itens com erro:<br>"
            }
            this.errorList += `<br>${JSON.stringify(i)}`;
        },
        handleFileUpload(file) {
            if (file) {
                const extension = file.name.split('.').pop();
                if (extension == "xls" || extension == "csv" || extension == "xlsx") {
                    try {
                        this.items = [];
                        this.routes = [];
                        this.errorList = null;
                        const reader = new FileReader();
                        reader.onload = (e) => {
                            const data = new Uint8Array(e.target.result);
                            const workbook = XLSX.read(data, { type: 'array' });
                            const worksheet = workbook.Sheets[workbook.SheetNames[0]];

                            const sheetData = XLSX.utils.sheet_to_json(worksheet, { header: 1 });

                            if (sheetData.length > 1) {
                                const indexCode = sheetData[0].indexOf('Código');
                                const indexClient = sheetData[0].indexOf('Cliente');
                                const indexRoute = sheetData[0].indexOf('Motorista');
                                const indexDriver = sheetData[0].indexOf('Dono');
                                if (indexCode >= 0 && indexRoute >= 0) {

                                    sheetData.slice(1).forEach((e) => {
                                        if (e && e.length > 0)
                                            if (e.length >= 2 && e[indexRoute].split("__").length >= 2) {
                                                const route = e[indexRoute].split("__")[0];
                                                const order = e[indexRoute].split("__")[1];

                                                let aux = this.routes.filter(e => e.name === route);
                                                if (aux.length > 0) {
                                                    aux[0].total++;
                                                    if (indexDriver >= 0 && e[indexDriver] && e[indexDriver].length > 0) {
                                                        if (aux[0].driver == "") {
                                                            aux[0].driver = [e[indexDriver]];
                                                        } else if (aux[0].driver.includes(e[indexDriver]) == false) {
                                                            aux[0].driver.push(e[indexDriver]);
                                                        }
                                                    }
                                                } else {
                                                    this.routes.push({
                                                        name: route,
                                                        driver: indexDriver >= 0 && e[indexDriver] && e[indexDriver].length > 0 ? [e[indexDriver]] : null,
                                                        total: 1,
                                                        bipped: 0,
                                                        needReceive: [],
                                                    })
                                                }
                                                if (route != null && order != null) {
                                                    this.items.push({
                                                        code: e[indexCode],
                                                        client: indexClient >= 0 ? e[indexClient] : "",
                                                        driver: indexDriver >= 0 && e[indexDriver] && e[indexDriver].length > 0 ? [e[indexDriver]] : null,
                                                        route: route,
                                                        order: order,
                                                        bipped: false,
                                                        bippedAt: null,
                                                        integrationControl: null,
                                                    })
                                                } else {
                                                    this.addError(e);
                                                }
                                            } else {
                                                this.addError(e);
                                            }
                                    });
                                } else {
                                    this.$eventHub.$emit("msgError", "Planilha fora do padrão.");
                                }
                            } else {
                                this.$eventHub.$emit("msgError", "Planilha vazia.");
                            }
                        };
                        reader.readAsArrayBuffer(file);
                    } catch {
                        this.$eventHub.$emit("msgError", "Erro na leitura do arquivo.");
                    }
                } else {
                    this.$eventHub.$emit("msgError", "Arquivo não compatível.");
                }
            }
        }
    }
};
</script>